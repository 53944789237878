.btn-loader,
.btn-loader:before,
.btn-loader:after {
  background: #ffffff;
  -webkit-animation: btnload1 1s infinite ease-in-out;
  animation: btnload1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.btn-loader {
  color: #ffffff;
  text-indent: -9999em;
  margin-top: 8px;
  margin-left: 15px;
  font-size: 3px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.btn-loader:before,
.btn-loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.btn-loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.btn-loader:after {
  left: 1.5em;
}
@-webkit-keyframes btnload1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes btnload1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
