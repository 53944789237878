@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


/* Side Scrollbar */
::-webkit-scrollbar {
    width: 7px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background: #80C41C;
    border-color: #80C41E;
    border-radius: 25px;
}

::-webkit-scrollbar-thumb:hover {
    background: #6ca713;
    border-color: #6aa510;
}
/* End Side Scrollbar */

body {
    margin: 0;
    font-family: "Barlow", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.full-body {
    height: 100%;
    padding-top: 60px!important;
    padding: 0px;
    margin: 0px;
    /* overflow: hidden; */
}

.px-500 {
    padding-top: 500px;
}
.navbar {
    /* font-family: Inter,sans-serif; */

    color: #000!important;
}

.nav-active > li > a  {
    font-weight: 600!important;
    color: #212529!important;
    font-size: 1.05rem;
}
.nav-active > li > a.active  {
    color: #80C41C!important;
}

.mobile-nav > a.active  {
    color: #80C41C!important;
}

.nav-active > li > a:after {
    display:block;
    content: '';
    border-bottom: solid 3px #80C41C;  
    transform: scaleX(0);  
    transition: transform 250ms ease-in-out;
}
  
.nav-active > li > a:hover:after {
    transform: scaleX(1);
}

.btn-master {
    text-decoration: none!important;
    background: #80C41C!important;
    color: white!important;
    border: solid 1px #80C41E!important;
    font-weight: 600!important;
}
.border-master {
    border-color: #80C41E!important;
}

.text-master {
    color: #80C41C!important;
}

.bg-master {
    background: #80C41C!important;
}

.btn-master:hover {
    background: #6ca713!important;
    color: white!important;
    border: solid 1px #6aa510!important;
}


.bg-main {
    background: #eeda8a!important;
}
.border-main {
    border-color: #FADF76!important;
}
.text-main {
    color: #FADF76!important;
}

.btn-main {
    text-decoration: none!important;
    background: #FADF76!important;
    color: white!important;
    border: solid 1px #f7da67!important;
    font-weight: 600!important;
}

.btn-main:hover {
    background: #f3d24f!important;
    color: white!important;
    border: solid 1px #fad335!important;
}


.icon-huge {
    font-size: 2rem;
}
.icon-xlg {
    font-size: x-large;
}

.icon-lg {
    font-size: larger;
}

.font-xm {
    font-size: small;
}
.font-xlg {
    font-size: x-large;
}
.font-lg {
    font-size: larger;
}
.outline-none {
    outline: none!important;
    box-shadow: none!important;
}

.btn-outline-none {
    outline: none!important;
    box-shadow: none!important;
}

@media (max-width: 991.98px) {
    .offcanvas-collapse {
      position: fixed;
      top: 56px; /* Height of navbar */
      bottom: 0;
      left: 100%;
      width: 100%;
      padding-right: 1rem;
      padding-left: 1rem;
      overflow-y: auto;
      visibility: hidden;
      background-color: rgb(236, 236, 236);
      transition: transform .3s ease-in-out, visibility .3s ease-in-out;
    }
    .offcanvas-collapse.open {
      visibility: visible;
      transform: translateX(-100%);
    }
  }
  .category-hero {
      font-family: Arial, Helvetica, sans-serif;
  }
  .category-hero > div > div > div > button {
    font-size: 16px!important;
    color: #1c1c1c!important;
  }

  .slick-slider > .slick-arrow {
      background: #80C41C!important;
      border-radius: 50%;
      box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
  }


  .img-hover-scale {
    transition: transform .3s; /* Animation */

  }
  
  .img-hover-scale:hover {
    transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .footer-bottom {
      background: #000;
  }

#scroll-top-btn {
    position:fixed;
    right:1rem;
    bottom:3rem;
    display:none;
    width:2.5rem;
    height:2.5rem;
    text-align:center;
    color:#fff;
    background:#02997b!important;
    line-height:30px;opacity:.5;z-index:200;
}
#scroll-top-btn:hover {
    transition:opacity .5s ease;
    -webkit-transform:translateY(-2px)!important;
    transform:translateY(-2px)!important;opacity:1;
}

.text-easy {
    color: rgb(70, 61, 61);
}

.item-title {
    text-decoration: none;
    color: rgb(70, 61, 61);
    font-weight: 500;
    font-size: 16px;
}
.item-title:hover {
    color: rgb(46, 38, 38)!important;
}
.item-card:hover {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}
.search-box:hover {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.footer-content {
    background: slategrey;
    -webkit-font-smoothing: antialiased;
}

.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.w-45 {
    width: 45%!important;
}

#result-empty{
    padding: 5rem;
    background-repeat: no-repeat;
    background-size: 200px;
    background-position: center;
}

#discount-container {
    right: 5px!important;
    top: 5px!important;
    z-index: 1;
}

.product-details{
    font-size: 17px;
    padding: 0;
    line-height: 1.6;
    font-family: Poppins,sans-serif!important;
}

.fw-800 {
    font-weight: 800;
}
.fw-500 {
    font-weight: 500;
}


@media (max-width: 576px) {
    .px-only-sm-1 {
        padding-left: 4px;
        padding-right: 4px;
    }
}

.img-profile {
    height: 2rem;
    width: 2rem;
}

.textarea-h-10 {
    height: 100px!important;
}

.h-category {
    height: 4.3vmin!important;
}

.h-slider {
    height: 50vmin!important;
}

.h-product {
    height: 18rem!important;
}

.border-dashed {
    border: dashed;
    border-color: rgb(233, 233, 233);
}

/* checkbox-input-group */

.checkbox-input-group {
    text-align: center;
    font-family: 'Roboto';
    position: relative;
}
 
.checkbox-input-group div {
    transition: .3s ease;
}
 
.checkbox-input-group input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
 
.checkbox-input-group input[type=checkbox]:checked ~ div {
    background-color: #50bcf2!important;
}
 /* End checkbox-input-group */

.font-roboto {
    font-family: 'Roboto';
}

.deb_zoom_in {
    animation: deb_zoom_in_key .5s;
}
@keyframes deb_zoom_in_key {
    0% {
        transform: scale(0, 0);
    }
    100% {
        transform: scale(1, 1);
    }
}

.deb_zoom_out {
    animation: deb_zoom_out_key .5s;
}
@keyframes deb_zoom_out_key {
    0% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(0, 0);
    }
}


.min-h-65 {
    min-height: 65px;
}

.z-0 {
    z-index: 0!important;
}
.z-1 {
    z-index: 1!important;
}
.z-2 {
    z-index: 2!important;
}
.z-3 {
    z-index: 3!important;
}
.z-4 {
    z-index: 4!important;
}
.z-5 {
    z-index: 5!important;
}

.top-except-nav {
    top: 75px!important;
}

.my-text-justify {
    text-align: justify;
    text-justify: inter-word;
}

.ckeditor-text-tableable table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border: 1px solid #e3e6f0;
}
.ckeditor-text-tableable table td, th{
    padding: .3rem;
    border: 1px solid #e3e6f0!important;
}
.ckeditor-text-tableable .table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

.h-dropzone {
    height: 140px;
}

/* Image Zoom */

.zoom-img-container {
    background-repeat: no-repeat;
    cursor: pointer;
  }
  
  .zoom-img-container:hover .zoom-img {
    opacity: 0;
  }
  
  .zoom-img {
    display: block;
    pointer-events: none;
  }
/* Image Zoom End */

/* Avatar */
.my-avatar {
    text-align: center;
    /* border-radius: 100%; */
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.my-sm-avatar {
    width: 55px;
    height: 55px;
}

.my-lg-avatar {
    width: 100px;
    height: 100px;
}
/* End Avatar */


/* Recevier video call */
.receiver-video-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
.receiver-video-container .my-video {
    position: absolute;
    bottom: 5px;
    right: 10px;
    width: 20%;
    height: 20%;
    z-index: 2;
}
.receiver-video-container .user-video {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.call-controller {
    position:absolute;
    z-index: 3;
    bottom: 30px;
    left: 50%; 
    transform: translateX(-50%);
}

.audio-call-container {
    position: absolute;
    top: 50%;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
}

.common-slider-loading-h {
    height: 10rem;
}
/* End receiver video call */

.bottom-5 {
    bottom: 5%;
}

.mirror-video{
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg); /* Safari and Chrome */
    -moz-transform:rotateY(180deg); /* Firefox */
}