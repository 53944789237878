.bg-loading {
    background: linear-gradient(-45deg, #ffffff, #dddddd, #ffffff, #dddddd);
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}